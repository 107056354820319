import React from 'react';

import { Link, NavLink, withRouter } from 'react-router-dom';
import { Login, Signup, suspense } from '../dialogs';
import { showDialog, pr, planSpecs } from '../../../ui-components/common';

import UserBurger from '../../../ui-components/user-burger';

@withRouter
export default class PublicHeader extends React.Component {
  state = {
    burger: false
  }
  headerMenu = React.createRef();

  componentDidMount() {
    window.buildsite.user().open(this.user_change);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.page !== this.props.page) {
      window.scrollTo(0, 0);
      this.setState({burger: false});
      document.body.classList.remove('overflow_hidden');
    }
  }

  componentWillUnmount() {
    window.buildsite.user().close(this.user_change);
  }

  signUpClick = e => {
    pr(e);
    let plan = this.props.page == 'pricingSmallBusiness' ? planSpecs.default_signup_plan : 'total';
    window.modal(suspense(Signup), {plan_id: plan});
  }

  loginClick = showDialog(suspense(Login), {isPublic: true})

  user_change = data => this.setState({ user: data })

  toggleBurger = () => {
    if (!this.state.burger && this.headerMenu.current) this.headerMenu.current.focus();
    document.body.classList.toggle('overflow_hidden');
    this.setState({burger: !this.state.burger});
  }

  isFeaturesActive = () => {
    if (this.props.page == "featuresSmallBusiness" || this.props.page == "featuresGCs" || this.props.page == "featuresSubs" || this.props.page == "featuresSuppliers") {
      return ' _active'
    }
  }
  isResourcesActive = () => {
    if (this.props.page == "quick-start"
      || this.props.page == "tutorials"
      || this.props.page == "submittal-templates") {
      return ' _active'
    }
  }
  isPricingActive = () => {
    if (this.props.page == "pricingGCs" || this.props.page == "pricingSubs" || this.props.page == "pricingSmallBusiness") {
      return ' _active'
    }
  }

  scrollIntoPricing = e => {
    pr(e);
    const y = document.getElementById("to_scroll").getBoundingClientRect().top +  window.scrollY - 140;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }

  getStartedLink = () => {
    if (this.props.page == "sign-up") return null;

    let getStartedUrl = _.get(window, 'buildsite.config.calendly_urls.schedule_a_call_url');

    if (this.props.page == "pricingSmallBusiness" || this.props.page == "featuresSmallBusiness") {
      return <a onClick={this.scrollIntoPricing} className='__btn _black'>Get started</a>
    } else if (this.props.page == "featuresSuppliers" && getStartedUrl) {
      return <a href={getStartedUrl} className='__btn _black'>Get started</a>
    }
    return <NavLink to="/sign-up" className='__btn _black'>Get started</NavLink>
  }

  renderHeaderMenu = () => {
    if (this.props.page === 'login') return null;
    return (
      <nav ref={this.headerMenu} className='header-menu'>
        <div className='header-menu_left'>
          {this.props.page !== 'turner' &&
            <div className='__dd'>
              <span className={`__dd-toggl ${this.isFeaturesActive() || ''}`}>Features<span className="boldHack">Features</span><div className="arrow"/></span>
              <div className='__dd-body'>
                <NavLink to='/features-gcs' activeClassName='_active'>
                  For General Contractors
                </NavLink>
                <NavLink to='/features-subs' activeClassName='_active'>
                  For Subcontractors
                </NavLink>
                {/*<NavLink to='/features-suppliers' activeClassName='_active'>
                  For Suppliers
                </NavLink>*/}
                <NavLink to='/features-small-business' activeClassName='_active'>
                  For Small Businesses
                </NavLink>
              </div>
            </div>
          }
          {this.props.page !== 'turner' &&
            <div className='__dd'>
              <span className={`__dd-toggl ${this.isPricingActive() || ''}`}>Pricing<span className="boldHack">Pricing</span><div className="arrow"/></span>
              <div className='__dd-body'>
                <NavLink to="/pricing/gcs" activeClassName='_active'>
                  For General Contractors
                </NavLink>
                <NavLink to="/pricing/subs" activeClassName='_active'>
                  For Subcontractors
                </NavLink>
                <NavLink to='/pricing/small-business' activeClassName='_active'>
                  For Small Businesses
                </NavLink>
              </div>
            </div>
          }
          <div className='__dd'>
            <span className={`__dd-toggl ${this.isResourcesActive() || ''}`}>Resources<span className="boldHack">Resources</span><div className="arrow"/></span>
            <div className='__dd-body'>
              <NavLink to="/quick-start" activeClassName='_active'>Quick Start Guide</NavLink>
              <NavLink to="/tutorials" activeClassName='_active'>Tutorials and Tips</NavLink>
              <NavLink to="/submittal-templates" activeClassName='_active'>Submittal Templates</NavLink>
              <a href="https://blog.submittal.com/" target='_blank'>Blog</a>
            </div>
            <div style={{fontWeight: '500', position: 'absolute', color: 'transparent'}}>.</div> {/*to download 500 font immediately*/}
          </div>
        </div>
        <div className='header-menu_right'>
          { this.state.user
          ? <UserBurger
              user={this.state.user}
              isPublic={true}
              items={
                this.state.user.status == "live"
                  ? [
                    <a href="/app/dashboard">Dashboard</a>,
                    <a href="/app/settings">Settings</a>
                  ]
                  : this.state.user.status == "ghost"
                    ? [ <a href="#" onClick={this.getStarted}>Sign-up</a> ]
                    : []
              }
            />
          : <>
              <a href="tel:8887178665" className="_call">Call (888) 717-8665</a>
              <a href='#'
                 onClick={this.loginClick}
                 className="_login"
              >Log in<span className="boldHack">Log in</span></a>
              <NavLink to="/contact" className='__btn'>Contact</NavLink>
              {this.getStartedLink()}
            </>
          }
        </div>
      </nav>
    );
  }

  render = () => (
    <header className={classNames('header', {'greyed_right': this.props.page == 'sign-up' || this.props.page == 'contact'})}>
      <Link to='/home' className='header-logo'/>
      <div className={`header-burger${this.state.burger ? ' _open' : ''}`} onClick={this.toggleBurger}>
        <div/><div/><div/>
      </div>
      { this.renderHeaderMenu() }
    </header>
  )
}
