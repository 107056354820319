import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { publicConstants } from './public-constants';

export default () =>
<footer className='footer'>
  <div className="footer-wrapper">
    <div className='__col'>
      <span className='__col-title'>Features</span>
      <NavLink to="/features-gcs" className='__col-href'>For General Contractors</NavLink>
      <NavLink to="/features-subs" className='__col-href'>For Subcontractors</NavLink>
      {/*<NavLink to="/features-suppliers" className='__col-href'>For Suppliers</NavLink>*/}
      <NavLink to="/features-small-business" className='__col-href'>For Small Businesses</NavLink>
    </div>
    <div className='__col'>
      <span className='__col-title'>Pricing</span>
      <NavLink to="/pricing/gcs" className='__col-href'>For General Contractors</NavLink>
      <NavLink to="/pricing/subs" className='__col-href'>For Subcontractors</NavLink>
      <NavLink to="/pricing/small-business" className='__col-href'>For Small Businesses</NavLink>
    </div>

    <div className='__col'>
      <span className='__col-title'>Resources</span>
      <NavLink to="/quick-start" className='__col-href'>
        <span style={{display: 'block', lineHeight: '20px', marginBottom: '13px', position: 'relative', top: '2px'}}>
          Quick Start Guide
        </span>
      </NavLink>
      <NavLink to="/tutorials" className='__col-href'>Tutorials and Tips</NavLink>
      <NavLink to="/submittal-templates" className='__col-href'>Templates</NavLink>
    </div>
    <div className='__col'>
      <span className='__col-title'>Company</span>
      <NavLink to="/aboutus" className='__col-href'>About</NavLink>
      <NavLink to="/termsofuse" className='__col-href'><span style={{lineHeight: '20px'}}>Terms of Use (Updated {publicConstants.dateTermsUpd})</span></NavLink>
      <NavLink to="/dataformat" className='__col-href'>Data Format</NavLink>
      <NavLink to="/privacypolicy" className='__col-href'>Privacy Policy</NavLink>
      <a href="https://buildsite.com/info/company/careers.html" className='__col-href' target='_blank' >
        Careers<i className="subicon _external-icon" style={{fontSize: '16px', marginLeft: '3px'}} />
      </a>
    </div>

    <div className='__col'>
    <span className='__col-title'>
      <NavLink to="/contact" className='__col-href titled'>Contact</NavLink>
    </span>
    </div>
    <div className='__col logo_info'>
      <div>
        <Link to='/home' className='__logo'/>
      </div>
    </div>
    <div className='__col comp_info'>
      {publicConstants.phone}<br/>
      Copyright 2017-{(new Date()).getFullYear()}<br/>
      BuildSite LLC<br/>
      All Rights Reserved.<br/>
    </div>
  </div>
</footer>;
